import { cadenzaSessionStorage, cadenzaLocalStorage } from 'cadenza/utils/storage/storage';
import { cadenzaTopWindow } from 'cadenza/utils/iframe';

const ITEM_SESSIONID = 'clientSessionId';
const ITEM_PERMALINK_URL = 'savedPermalinkUrl';

/**
 * Method to call when Cadenza Web base is loaded: Clears the sessionStorage and localStorage
 * and sets the savedPermalinkUrl to the sessionStorage if needed.
 */
export function initializeCadenzaStorage () {
  const clientSessionId = window.Disy.clientSessionId;

  if (cadenzaLocalStorage.getItem(ITEM_SESSIONID) !== clientSessionId) {
    cadenzaLocalStorage.clear();
    cadenzaLocalStorage.setItem(ITEM_SESSIONID, clientSessionId);
  }

  if (cadenzaSessionStorage.getItem(ITEM_SESSIONID) !== clientSessionId) {
    const savedPermalinkUrl = cadenzaSessionStorage.getItem(ITEM_PERMALINK_URL);

    cadenzaSessionStorage.clear();
    cadenzaSessionStorage.setItem(ITEM_SESSIONID, clientSessionId);

    if (savedPermalinkUrl) {
      cadenzaSessionStorage.setItem(ITEM_PERMALINK_URL, savedPermalinkUrl);
    }
  }

  const savedPermalinkUrl = window.Disy.savedPermalinkUrl;
  if (cadenzaTopWindow === window && savedPermalinkUrl) {
    cadenzaSessionStorage.setItem(ITEM_PERMALINK_URL, savedPermalinkUrl);
  }
}
