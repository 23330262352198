import { isFeatureAvailable } from 'cadenza/features';
import { logger } from 'cadenza/utils/logging';
import { getUrlParam } from 'cadenza/utils/url-param-utils';

interface ListenerRegistration {
  listener: EventListener;
  types: string[];
}

export interface WebApplicationPostMessageConfig {
  targetOrigin: string;
  targetIFrameSelector: string;
}

interface SendEventOptions {
  targetOrigin?: string;
  targetIFrameSelector?: string;
  targetWebApplicationName?: string;
  shouldStringify?: boolean;
}

const listeners: ListenerRegistration[] = [];
/**
 * @type {string} webApplicationNameUrlParameter - Name for the lookup in the window.Disy.registeredPostMessageWebApplications to use
 * the origin and the targetIFrameSelector configured in the webapplicationconfiguration-config.xml
 * If this webApplicationName is provided as URL parameter (via Cadenza Web URL API) and the configuration
 * for this webApplication in the registeredPostMessageWebApplications has an origin and
 * a targetIFrameSelector specified, the origin and the targetIFrameSelector of the methodCall are ignored.
 * So the configuration of the webApplicationNameUrlParameter "wins" over the parameters of the method call.
 */
const webApplicationNameUrlParameter = getUrlParam({ name: 'webApplicationName' });
/**
 * @type {string} context - If this context is provided as URL parameter (via Cadenza Web URL API)
 * it will be added to each payload sent via postmessage to enable the CustomWebApplication
 * to identify which Cadenza Web instance was sending this postMessage when receiving one.
 */
const context = getUrlParam({ name: 'context' });

/**
 * The PostMessage provides functions for the integration framework communication via postMessage
 */
class ClassicPostMessage {

  constructor () {
    if (isFeatureAvailable('WEBAPP_INTEGRATION')) {
      window.addEventListener('message', onMessage);
      if (window.Disy.debug) {
        this.registerEventListener(debugPostMessageEventResponder as EventListener, []);
      }
    }
  }

  /**
   * Executes a postMessage with the targetOrigin to the window specified with jQuery via the targetIFrameSelector
   * (or the parent window if targetIFrameSelector is null or empty) and uses the JSON data as a message.
   *
   * @param data - to send to the other window
   * @param [options] - Options
   * @param [options.targetOrigin] - Defaults to location.origin, the target origin to which we're sending the message
   * @param [options.targetIFrameSelector] - In case we need to send the message to a sibling iframe.
   * This may not be used by anybody but we think it's a nice feature, not expensive to maintain.
   * @param [options.targetWebApplicationName] - Name for the lookup in the window.Disy.registeredPostMessageWebApplications to use
   * the origin and the targetIFrameSelector configured in the webapplicationconfiguration-config.xml
   * If this webApplicationName is provided as URL parameter or with this parameter targetWebApplicationName
   * and the configuration for this webApplication in the registeredPostMessageWebApplications has an origin and
   * a targetIFrameSelector specified, the origin and the targetIFrameSelector of the methodCall are ignored.
   * So the configuration of the webApplicationNameUrlParameter "wins" over the targetWebApplicationName and this
   * "wins" over the parameters of the method call.
   * @param [options.shouldStringify] - @deprecated : stringifies the data - We cannot just remove this and always send JSON,
   * even though that would technically work, the issue is we have some customers which are expecting to receive a string when this is true,
   * and they are doing JSON.parse on it, removing this would break their app.
   */
  sendEvent (data: Record<string, unknown>, {
    targetOrigin = location.origin,
    targetIFrameSelector,
    targetWebApplicationName,
    shouldStringify }: SendEventOptions = {}) {
    if (webApplicationNameUrlParameter || targetWebApplicationName) {
      const config = this._getWebApplicationConfig([ webApplicationNameUrlParameter, targetWebApplicationName ]);
      targetOrigin = config?.targetOrigin ?? targetOrigin;
      targetIFrameSelector = config?.targetIFrameSelector ?? targetIFrameSelector;
    }

    const targetWindow = getTargetWindow(targetIFrameSelector);

    if (context) {
      data.context = context;
    }
    const payload = shouldStringify ? JSON.stringify(data) : data;

    sendPostMessage(targetWindow, targetOrigin, payload);
  }

  /**
   * Registers a listener for PostMessages.
   * The listener is called for the given event types or all types when none are given.
   *
   * @param listener - to be executed on specific event types
   * @param types - on which the listener should execute
   */
  registerEventListener (listener: EventListener, types: string[]) {
    if (!isFeatureAvailable('WEBAPP_INTEGRATION') && listener !== debugPostMessageEventResponder) {
      logger.debug('Make sure the plugin "Integration_Application_Framework" is enabled in the "plugins.xml" file');
    }
    listeners.push({ listener, types });
  }

  /**
   * Unregisters a listener for PostMessages which was registered before
   *
   * @param listener - to be removed from the listeners to execute
   */
  unregisterEventListener (listener: EventListener) {
    const indexOfListenerToRemove = listeners.findIndex((listenerDefinition) => listenerDefinition.listener === listener);
    if (indexOfListenerToRemove > -1) {
      listeners.splice(indexOfListenerToRemove, 1);
    }
  }

  _getWebApplicationConfig (webApplicationNamesToCheck: (string | null | undefined)[]): WebApplicationPostMessageConfig | undefined {
    let config: WebApplicationPostMessageConfig | undefined;
    webApplicationNamesToCheck.find(webApplicationName => {
      if (webApplicationName) {
        config = window.Disy.registeredPostMessageWebApplications?.[webApplicationName];
        if (!config) {
          logger.warn(`There is no custom web application named "${webApplicationName}" for which PostMessage based communication has been configured. No PostMessage is sent.`);
        }
      }
      return config;
    });
    return config;
  }

}

export const classicPostMessage = new ClassicPostMessage();

function getEventType (event: MessageEvent) {
  try {
    return event.data.type;
  } catch (error) {
    logger.debug('Incompatible PostMessage encountered from origin: ' + event.origin + '\n' + (error as Error).stack);
  }
}

function isSupportedEventType (currentEventType: string, supportedEventTypes: string[]) {
  return !supportedEventTypes || !supportedEventTypes.length || supportedEventTypes.includes(currentEventType);
}

/**
 * For debugging purposes, this listener is registered. Incoming messages are logged on the console.
 *
 * @param event - postMessage event
 */
function debugPostMessageEventResponder (event: MessageEvent) {
  logger.log(`Cadenza got a message from a businessApp, origin: ${event.origin} , payload: ${JSON.stringify(event.data)}`);
}

function getTargetWindow (targetIFrameSelector?: string) {
  let targetWindow = window.parent; // default is self or parent
  if (targetIFrameSelector) {
    if (targetIFrameSelector === 'window.opener') { // in case of current window is a popup without parent and configuration of targetWindowSelector in webapplicationconfiguration-config.xml says "window.opener"
      targetWindow = window.opener;
    } else {
      try {
        const targetIframe = window.parent.document.querySelector(targetIFrameSelector) as HTMLIFrameElement;
        targetWindow = targetIframe.contentWindow!;
      } catch (e) {
        logger.warn(`The targetWindowSelector of the current postMessage to be sent was configured to be [${targetIFrameSelector}], but that ID doesn't work, so the default is used as targetWindow. The error occured is: `, e);
      }
    }
  }
  return targetWindow;
}

function logDebugInformationForPostMessageToSend (targetWindow: Window, targetOrigin: string, payload: string | Record<string, unknown>) {
  const postMessageInformationForDebugging = {
    targetWindow,
    targetOrigin,
    payload
  };
  logger.debug('PostMessage debugging information: ', postMessageInformationForDebugging);
}

function sendPostMessage (targetWindow: Window, targetOrigin: string, payload: string | Record<string, unknown>) {
  // We cannot be always sure that we are having an actual targetWindow to send the message to, check comment in /default/map.js
  if (targetWindow) {
    logger.debug('Trying to send a PostMessage now.');
    logDebugInformationForPostMessageToSend(targetWindow, targetOrigin, payload);
    try {
      targetWindow.postMessage(payload, targetOrigin);
    } catch (e) {
      logger.error('An error occured while trying to send a PostMessage.', e);
    }
  } else {
    logger.debug('A call for sending a PostMessage occured, but there\'s no targetWindow to which Cadenza can send this PostMessage');
    logDebugInformationForPostMessageToSend(targetWindow, targetOrigin, payload);
  }
}

function isOriginRegistered (origin: string) {
  if (!window.Disy.registeredPostMessageWebApplications) { // Pebble templates don't include registeredPostMessageWebApplications yet
    return false;
  }
  return Object.values(window.Disy.registeredPostMessageWebApplications)
    .some(({ targetOrigin }) => targetOrigin === origin || targetOrigin === '*');
}

function onMessage (event: MessageEvent) {
  const origin = event.origin;

  // Accept only messages with registered origin.
  if (origin && isOriginRegistered(origin)) {
    const eventType = getEventType(event);
    let eventHandled = false;

    if (eventType) {
      listeners.forEach(function (listenerDefinition) {
        if (isSupportedEventType(eventType, listenerDefinition.types)) {
          try {
            listenerDefinition.listener(event);
          } catch (error) {
            logger.error('Failed to call PostMessage listener', error);
          }
          eventHandled = true;
        }
      });
    }

    if (!eventHandled) {
      logger.debug('Received PostMessage with unknown event type from origin: ' + origin);
    }
  }
}
