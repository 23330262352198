import h from 'hyperscript';
import $ from 'jquery';

import 'ui/panel-section/panel-section.css';

// Must be the same as the "navigator breakpoint" set in CSS.
import { currentWindowMediaMdUp } from 'cadenza/utils/media';

import './sidebar.css';

const COMPONENT_NAME = 'd-sidebar';
const closeButtonClass = `.${COMPONENT_NAME}-close`;

export class Sidebar extends HTMLElement {

  readonly _backdrop = h(`.${COMPONENT_NAME}--backdrop`);
  _previousOpenState = this.open;
  _shouldOpenOnMobile;
  _autoOpen = false;
  _closable = false;

  constructor ({ autoOpen = true, shouldOpenOnMobile = false } = {}) {
    super();
    this._shouldOpenOnMobile = shouldOpenOnMobile;
    $(this)
      .on('keydown', event => {
        if (event.key === 'Escape' && this.closable) {
          this.close();
        }
      })
      .on('click', closeButtonClass, () => this.close());

    // It is important that this line comes before "this.autoOpen = autoOpen".
    // Otherwise in "set autoOpen" a listener with a wrong "this" is registered.
    this._onMediaChange = this._onMediaChange.bind(this);
    this.autoOpen = autoOpen;
  }

  close () {
    this.open = false;
    this.dispatchEvent(new CustomEvent('close'));
  }

  connectedCallback () {
    this.setAttribute('role', 'complementary');
    this.classList.add(COMPONENT_NAME);
    this._updateVisibility();
    this.parentElement!.insertBefore(this._backdrop, this.nextSibling);
    this._onMediaChange();
  }

  _updateVisibility (value = this.open) {
    this.style.display = value ? '' : 'none';
  }

  disconnectedCallback () {
    currentWindowMediaMdUp.removeListener(this._onMediaChange);
    this._backdrop.remove();
  }

  _onMediaChange () {
    if (this.open && this.#autoHideWantsItHidden) {
      this.open = false;
      this._previousOpenState = true;
    } else if (!this.open && !this.#autoHideWantsItHidden && this._previousOpenState) {
      this.open = true;
    }
  }

  get #autoHideWantsItHidden () {
    return !currentWindowMediaMdUp.matches;
  }

  set open (value: boolean) {
    if (value !== this.open) {
      if (value || !(this.classList.contains('is-overlay') || this.classList.contains('is-animated'))) {
        this._updateVisibility(value);
      }
      this.setAttribute('aria-hidden', String(!value));
      this.dispatchEvent(new CustomEvent('change:open', { detail: this.open }));
    }
  }

  /**
   * @protected
   * @return {boolean} Whether the sidebar is open or not
   */
  get open () {
    return this.getAttribute('aria-hidden') !== 'true';
  }

  set autoOpen (value: boolean) {
    if (value) {
      this.open = this.open && (!this.#autoHideWantsItHidden || this._openOnMobile);
      currentWindowMediaMdUp.addListener(this._onMediaChange);
    } else {
      currentWindowMediaMdUp.removeListener(this._onMediaChange);
    }
    this._autoOpen = Boolean(value);
  }

  /**
   * @protected
   * @return {boolean} Whether the sidebar is supposed to be open initially or not
   */
  get autoOpen () {
    return this._autoOpen;
  }

  set closable (value) {
    this._closable = Boolean(value);
  }

  /**
   * @protected
   * @return {boolean} Whether the sidebar is closeable or not
   */
  get closable () {
    if (typeof this._closable === 'undefined') {
      return $(this).find(`${closeButtonClass}:visible`).length > 0;
    }
    return this._closable;
  }

  get _openOnMobile () {
    return this._shouldOpenOnMobile || this.getAttribute('open-on-mobile') === 'true';
  }

}

customElements.define(COMPONENT_NAME, Sidebar);
