import h from 'hyperscript';
import infoIcon from '@disy/cadenza-icons/info.svg';

import { icon } from 'cadenza/utils/icon/icon';
import { cadenzaUrl } from 'cadenza/utils/cadenza-url/cadenza-url';

import './info-button.css';

import { showHelpDialog } from 'cadenza/navigation/help-dialog/help-dialog';

import i18n from './messages.properties';

const NAME = 'd-info-button';

/**
 * This info button is shown in the subnav, it opens the help dialog of the repository item, which is currently open.
 * For the button which shows a tooltip flyout on click, see ui/toggletip/toggletip.
 */
export class InfoButton extends HTMLElement {

  constructor () {
    super();
    this.button = h('button.button.button-borderless.button-s',
      {
        type: 'button',
        title: i18n('info.button.title'),
        onclick: () => this._onClick()
      },
      icon(infoIcon),
      h('.visuallyhidden', i18n('info.button.title')));
  }

  connectedCallback () {
    this.classList.add(NAME);
    this.appendChild(this.button);
  }

  get dialogTitle () {
    return this.getAttribute('dialog-title');
  }

  get helpUrl () {
    return this.getAttribute('help-url');
  }

  _onClick () {
    showHelpDialog(cadenzaUrl(this.helpUrl), this.dialogTitle);
  }

}
customElements.define(NAME, InfoButton);
