import h from 'hyperscript';
import viewsIcon from '@disy/cadenza-icons/views.svg';

import { createButton } from 'ui/button/button';

import { icon } from 'cadenza/utils/icon/icon';

import i18n from './messages.properties';

export function createProcessesButton (processesLoader, { menuButton, ...buttonOptions } = {}) {
  const button = createButton([ icon(viewsIcon), h('span.button--label', i18n('processesButton')) ], buttonOptions);
  importAndSetupProcessesButton(button, processesLoader, menuButton);
  return button;
}

async function importAndSetupProcessesButton (button, processesLoader, menuButton) {
  const { setupProcessesButton } = await import(/* webpackChunkName: "processes-button" */ 'cadenza/processes/processes-button');
  setupProcessesButton(button, processesLoader, { menuButton });
}
