import { ajax } from 'cadenza/api-client/api';
import { getCurrentHierarchyParameters } from 'cadenza/api-client/processing-param-util';
import type { RepositoryName } from 'cadenza/repository/model/repository-name';
import { type CadenzaUrl, cadenzaUrl } from 'cadenza/utils/cadenza-url/cadenza-url';
import { getUrlParam } from 'cadenza/utils/url-param-utils';

/**
 * A processing of table result data into another type of visualization.
 *
 * @see net/disy/cadenza/repository/api/template/ITemplateProcessingDto.java
 */
interface ITemplateProcessingDto {
  /** label of the processing */
  name: string;
  previewImage: string;
  /** type of template, @see net/disy/cadenza/api/repository/TypeGroup.java */
  groupType: string;
  /** long form description */
  description: string;
  /** label for the category */
  categoryLabel: string;
  /** path from root */
  processingPath: string[];
  /** has result limits set? */
  hasResultLimits: boolean;
  /** hash of all set condition values */
  conditionValueSetHash: string;
  priority: number;
}

/**
 * Collection of Processings for a given selector.
 *
 * @see net/disy/cadenza/gis/web/layer/processings/facade/SelectorContextWithProcessingsDto.java
 */
interface SelectorContextWithProcessingsDto {
  /** name of the selector the processings belong to */
  selectorName: string;
  /** additional views */
  processing: ITemplateProcessingDto[];
  /** label for the selector */
  selectorLabel: string;
}

/** @see net/disy/cadenza/processing/dto/ConditionDescriptionDto.java */
interface ConditionDescriptionDto {
  name: string;
  printName: string;
  attributes: ConditionAttributeDto[];
}

/** @see net/disy/cadenza/processing/dto/ConditionAttributeDto.java */
interface ConditionAttributeDto {
  name: string;
  printName: string;
}

/** @see net/disy/cadenza/processing/dto/RepositoryInfo.java */
interface RepositoryInfo {
  name: string;
}

export function getProcessings () {
  const data = {
    selector: getUrlParam({ name: 'selector' })
  };
  Object.assign(data, getCurrentHierarchyParameters());
  return ajax<ITemplateProcessingDto>('/processings/', {
    data,
    traditional: true
  });
}

export function createPreviewImageUrl (repositoryName: RepositoryName, previewImage: string): CadenzaUrl | undefined {
  if (repositoryName && previewImage) {
    return cadenzaUrl(`/metadata/${previewImage}?repositoryName=${repositoryName}&preview=true`);
  }
}

/**
 * Retrieves selector contexts with processings
 *
 * @param layerId - id of the layer to check for processings
 * @return all processings (further views) for the given layer.
 */
export function getLayerProcessings (layerId: string) {
  return ajax<SelectorContextWithProcessingsDto>(`/layers/${layerId}/processings`);
}

export class ProcessingApi {

  #urlParams: URLSearchParams;

  constructor (urlSearchParams: URLSearchParams) {
    this.#urlParams = urlSearchParams;
  }

  getConditions () {
    const selector = this.#urlParams.get('selector');
    return ajax<ConditionDescriptionDto[]>('/processings/conditions', {
      data: {
        selector
      }
    });
  }

  getRepository () {
    const selector = this.#urlParams.get('selector');
    return ajax<RepositoryInfo>('/processings/repository', {
      data: {
        selector
      }
    });
  }

}
