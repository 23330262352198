import h from 'hyperscript';

import 'ui/truncated-p/truncated-p';
// The following import is used only for type checking and then removed.
import type { TruncatedP } from 'ui/truncated-p/truncated-p';

import { escapeAndMarkHTML } from 'cadenza/utils/escape-and-mark';

import './alert.css';

// we need the array of strings for internal implementation
const ALERT_TYPES = [ 'error', 'info', 'success', 'warning' ] as const;
const COMPONENT_NAME = 'd-alert';

// derive the type from the array of strings
export type AlertType = typeof ALERT_TYPES[number];

export class Alert extends HTMLElement {

  #legacyTitleElement?: HTMLParagraphElement;
  #legacyMessageElement?: TruncatedP;

  connectedCallback () {
    this.setAttribute('role', 'alert');
    this.classList.add(COMPONENT_NAME);

    if (!this.size && this.closest('.d-modal,.d-popup')) {
      this.size = 's';
    }

    const isLegacyAlert = this.matches('[type],[title],[message],[truncated-message]');
    if (isLegacyAlert) {
      const title = this.getAttribute('title');
      if (title) {
        this.title = title;
      }

      const typeAttr = this.getAttribute('type');
      if (typeAttr) {
        this.type = typeAttr as AlertType; // type assertion is fine, because the setter is safe
      }

      const message = this.getAttribute('message');
      if (message) {
        this.message = message;
      }

      const truncatedMessage = this.getAttribute('truncated-message');
      if (truncatedMessage) {
        this.truncatedMessage = truncatedMessage;
      }
    }
  }

  set type (value: AlertType) {
    ALERT_TYPES.forEach(type => this.classList.toggle(`d-alert-${type}`, (value === type)));
  }

  get type (): AlertType {
    return ALERT_TYPES.find(type => this.classList.contains(`d-alert-${type}`)) || 'error';
  }

  set size (size: '' | 's' | 'xs') {
    this.classList.toggle('d-alert-small', size === 's');
    this.classList.toggle('d-alert-xs', size === 'xs');
  }

  get size () {
    if (this.classList.contains('d-alert-xs')) {
      return 'xs';
    }
    if (this.classList.contains('d-alert-small')) {
      return 's';
    }
    return '';
  }

  /** @deprecated Set the content of the alert via DOM APIs instead. */
  override set title (text: string) {
    this.#initLegacyElements();
    // eslint-disable-next-line no-unsanitized/property
    this.#legacyTitleElement!.innerHTML = text ? escapeAndMarkHTML(text) : '';
  }

  /** @deprecated Get the content of the alert via DOM APIs instead. */
  override get title () {
    return this.#legacyTitleElement?.innerHTML ?? '';
  }

  /** @deprecated Set the content of the alert via DOM APIs instead. */
  set message (text: string) {
    this.#initLegacyElements();
    this.#legacyMessageElement!.fullText = text;
  }

  /** @deprecated Get the content of the alert via DOM APIs instead. */
  get message () {
    return this.#legacyMessageElement?.fullText ?? '';
  }

  /** @deprecated Set the content of the alert via DOM APIs instead. */
  set truncatedMessage (text: string | undefined) {
    this.#initLegacyElements();
    this.#legacyMessageElement!.truncatedText = text;
  }

  /** @deprecated Get the content of the alert via DOM APIs instead. */
  get truncatedMessage () {
    return this.#legacyMessageElement?.truncatedText ?? '';
  }

  #initLegacyElements () {
    if (!this.#legacyTitleElement) {
      this.#legacyTitleElement = h('p.title');
      this.#legacyMessageElement = h('d-truncated-p.message');

      // Firefox + screen reader won't work here: https://github.com/nvaccess/nvda/issues/8873
      this.append(this.#legacyTitleElement, this.#legacyMessageElement);
    }
  }

}

customElements.define(COMPONENT_NAME, Alert);
