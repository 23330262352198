import h from 'hyperscript';
import infoIcon from '@disy/cadenza-icons/info.svg';
import checkSmallIcon from '@disy/cadenza-icons/check-small.svg';
import warningIcon from '@disy/cadenza-icons/warning.svg';
import errorIcon from '@disy/cadenza-icons/error.svg';
import closeIcon from '@disy/cadenza-icons/close-small.svg';

import { createIconButton } from 'ui/button/button';
import type { Content } from 'ui/content';
import 'ui/toast-message/toast-message.css';

import { on } from 'cadenza/utils/event-util';
import type { Icon } from 'cadenza/utils/icon/icon';
import { icon } from 'cadenza/utils/icon/icon';
import { executeAfterAnimation } from 'cadenza/utils/animation-util';
import { assert } from 'cadenza/utils/custom-error';

export const TOAST_MESSAGE_COMPONENT_NAME = 'd-toast-message';
const DEFAULT_FADE_OUT_TIME_MS = 3000;
const MESSAGE_PARENT_CLASS = 'd-toast-message-parent';

const ICONS = {
  info: infoIcon,
  success: checkSmallIcon,
  warning: warningIcon,
  error: errorIcon
};

type ToastMessageType = 'info' | 'success' | 'warning' | 'error';
const darkBgType: ToastMessageType[] = [ 'error', 'success', 'info' ];

export interface  ToastMessageOptions {
  /** The message type. Default=info*/
  type?: ToastMessageType;
  /**
   * The HTML element in whose bottom right corner the toast message is going to be displayed.
   * If unspecified the message is added in the standard toast message area. *
   */
  parentElement?: HTMLElement;
  /** If true, the message automatically fades out after fadeOutTime. Default=true*/
  fadeOut?: boolean;
  /** If set, fadeout will start after the given amount of time in ms. If not, the default will be used.*/
  fadeOutTimeMs?: number;
  /** If true, a close button is added so the use can manually close the message. Default=false*/
  closeable?: boolean;
  /** Specify an icon to override the default icons.*/
  icon?: Icon;
  /** Specify an additional CSS class to add to the toast message elements */
  styleClass?: string;
}

const DEFAULT_OPTIONS: ToastMessageOptions = {
  type: 'info',
  fadeOut: true,
  closeable: false,
  fadeOutTimeMs: DEFAULT_FADE_OUT_TIME_MS
};

type ToastMessageContent = Content;

/**
 * Shows a toast message.
 *
 * When the message is closed, whether because of time out or a user action,
 * a "close" CustomEvent gets dispatched.
 *
 * @param content - message content
 * @param [options] - Options
 * @return toast message
 *
 * Note: In order to trigger the display of a toast message on the main page from inside an iframe,
 * use the post message api (for example workbookMapIframeApi.showToastMessage()).
 */
export function showToastMessage (content: ToastMessageContent, options: ToastMessageOptions = {}) {
  const message = new ToastMessage(content, options);
  const messageParent = getMessageParent(options.parentElement);
  messageParent.prepend(message);
  return message;
}

function getMessageParent (scope: HTMLElement = document.querySelector('.d-modal:not([hidden], .closed)') ?? document.body): Element {
  let messageParent = scope.querySelector(`:scope > .${MESSAGE_PARENT_CLASS}`);
  if (!messageParent) {
    messageParent = h('.d-toast-message-parent');
    scope.append(messageParent);
  }
  return messageParent;
}

function isDarkBg (type: ToastMessageType) {
  return darkBgType.includes(type);
}

export class ToastMessage extends HTMLElement {

  _options;
  _content;

  /**
   * Constructor
   *
   * @param content - message content
   * @param [options] - Options
   */
  constructor (content: ToastMessageContent, options: ToastMessageOptions) {
    super();
    assert(content, 'Toast message content is mandatory');
    this._content = content;
    this._options = Object.assign({}, DEFAULT_OPTIONS);
    Object.assign(this._options, options);
  }

  connectedCallback () {
    this.classList.add(TOAST_MESSAGE_COMPONENT_NAME);
    this.classList.add(`${TOAST_MESSAGE_COMPONENT_NAME}-${this._type}`);
    if (isDarkBg(this._type)) {
      this.classList.add('dark-bg');
    }
    this.classList.add('faded-out');
    if (this._options.styleClass) {
      this.classList.add(this._options.styleClass);
    }
    this.setAttribute('role', 'alert');

    this.append(
      this._determineIcon(),
      h(`.${TOAST_MESSAGE_COMPONENT_NAME}--text`, this._content)
    );

    if (this._options.closeable) {
      this.append(this._createCloseButton());
    }

    setTimeout(() => {
      if (this._options.fadeOut ?? true) {
        this._fadeOut();
      }
      this.classList.remove('faded-out');
    });
  }

  _determineIcon () {
    return this._options.icon
      ? this._options.icon
      : icon(ICONS[this._type]);
  }

  _fadeOut () {
    executeAfterAnimation(this, () => {
      setTimeout(() => {
        this.classList.add('faded-out');
        this._dispatchCloseEvent();
        executeAfterAnimation(this, () => this.remove());
      }, this._options.fadeOutTimeMs);
    });
  }

  _createCloseButton () {
    const closeButton = createIconButton(
      icon(closeIcon),
      'close',
      {
        variant: 'borderless',
        size: 'xs',
        styleClass: 'close-button'
      }
    );
    on(closeButton, 'click', () => {
      this._dispatchCloseEvent();
      this.remove();
    });
    return closeButton;
  }

  _dispatchCloseEvent () {
    this.dispatchEvent(new CustomEvent('close'));
  }

  get _type (): ToastMessageType {
    return this._options.type!; // cannot be undefined as we use default values for the options
  }

}

customElements.define(TOAST_MESSAGE_COMPONENT_NAME, ToastMessage);
