import { logger } from 'cadenza/utils/logging';

const STORAGE_CADENZA_PREFIX = '$cadenza$';

/**
 * Wrapper class around the native sessionStorage or localStorage
 *
 * Please use it everywhere you would access the native storage directly.
 * The API is equivalent to the storage API.
 *
 * Reasons for the wrapping:
 * - We want to clear the storage if there's a new session to avoid a user from seeing the data of
 *   another user. Note: This is not a security feature, the client is unsafe anyway.
 * - When clearing the storage, we need to distinguish between items that are stored by Cadenza and
 *   items that are stored by customer applications, because they live in the same origin
 *   (i.e. by using a reverse proxy). Only the items that are store by Cadenza should be cleared.
 * - Also the wrapper catches errors that might be thrown when the storage is full or when cookies
 *   are disabled via browser setting (which also disables DOM storage). Our code must not rely on
 *   the storage being present. That's why we only log these errors on warning level.
 */
class CadenzaStorage {

  #storage;

  constructor (storageSupplier: () => Storage) {
    try {
      this.#storage = storageSupplier();
    } catch (error) {
      logger.warn('Storage is disabled', error);
    }
  }

  setItem (key: string, value: string) {
    try {
      this.#storage?.setItem(STORAGE_CADENZA_PREFIX + key, value);
    } catch (error) {
      logger.warn('Storage is full and will be cleared automatically', error);
      this.#storage!.clear(); // Better clear the storage instead of failing with every setItem() call.
    }
  }

  getItem (key: string) {
    return this.#storage?.getItem(STORAGE_CADENZA_PREFIX + key) ?? null;
  }

  removeItem (key: string) {
    this.#storage?.removeItem(STORAGE_CADENZA_PREFIX + key);
  }

  clear () {
    const storage = this.#storage;
    if (storage) {
      for (const [ name ] of Object.entries(storage)) {
        if (name.startsWith(STORAGE_CADENZA_PREFIX)) {
          storage.removeItem(name);
        }
      }
    }
  }

}

export const cadenzaSessionStorage = new CadenzaStorage(() => window.sessionStorage);
export const cadenzaLocalStorage = new CadenzaStorage(() => window.localStorage);
