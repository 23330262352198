import infoIcon from '@disy/cadenza-icons/info.svg';
import h from 'hyperscript';

import { Modal } from 'ui/dialog/modal/modal';

import { logger } from 'cadenza/utils/logging';
import { icon } from 'cadenza/utils/icon/icon';

import './help-dialog.css';
import i18n from './help-dialog.properties';

const NAME = 'd-help-dialog';
const AUTO_POPUP_STORAGE_KEY = 'CadenzaWebInfoAutoPopup';

/**
 * Displays additional information for a repository item (selector, theme, ...) in a modal dialog.
 *
 * @param {string} contentUrl - The URL that is to be displayed in the content iFrame of the dialog
 * @param {string} title - dialog title
 */
export function showHelpDialog (contentUrl, title) {
  const dialog = new HelpDialog({ contentUrl, title });
  showHelpDialogInternal(dialog);
}

/**
 * Displays additional information for a repository item (selector, theme, ...) in a modal dialog, if
 * the automatic display of this dialog has not been disabled before (by a checkbox writing to local
 * storage)
 *
 * @param {string} contentUrl - The URL that is to be displayed in the content iFrame of the dialog
 * @param {string} title - dialog title
 * @param {string} [repositoryItemIdHashForDisableAutoPopupCheckbox] - hash to use for reading and
 *   saving the disabling of the automatic displaying of the help dialog for this item.
 */
export function showHelpDialogIfNotLocallyDisabled (contentUrl, title, repositoryItemIdHashForDisableAutoPopupCheckbox) {
  const dialog = new HelpDialog({ contentUrl, title, repositoryItemIdHashForDisableAutoPopupCheckbox });
  if (!dialog.isAutoPopupLocallyDisabled()) {
    showHelpDialogInternal(dialog);
  }
}

function showHelpDialogInternal (dialog) {
  document.body.append(dialog);
  dialog.addEventListener('close', () => dialog.remove());
  dialog.showModal();
}

export class HelpDialog extends Modal {

  #initialized = false;
  #contentUrl;
  #repositoryItemIdHashForDisableAutoPopupCheckbox;
  #autoPopupStorage;

  /**
   * @param {object} options - constructor options
   * @param {string} options.contentUrl - The URL that is to be displayed in the content iFrame of the dialog
   * @param {string} options.title - dialog title
   * @param {string} [options.repositoryItemIdHashForDisableAutoPopupCheckbox] - This parameter should
   *   be specified if the help dialog is being opened automatically after a repository item was opened.
   *   If present, a checkbox will be displayed in the footer of the dialog with which the automatic
   *   opening of the dialog can be disabled for the future (saved in the local storage of the browser).
   */
  constructor ({ contentUrl, title, repositoryItemIdHashForDisableAutoPopupCheckbox }) {
    super({ titleIcon: icon(infoIcon), title });
    this.#contentUrl = contentUrl;
    this.#repositoryItemIdHashForDisableAutoPopupCheckbox = repositoryItemIdHashForDisableAutoPopupCheckbox;
    try {
      // Do not use the cadenzaLocalStorage from storage.ts, as the use case here is just a classic feature
      // based on the assumption that storing in localStorage survives a logout/login process.
      // This is completely contrary to the approach that the same browser is used by different users
      // and thus information of one user should not end up at the other
      // and thus all information of a user is deleted on logout.
      this.#autoPopupStorage = window.localStorage;
    } catch (error) {
      logger.warn('Storage is disabled', error);
    }
  }

  connectedCallback () {
    super.connectedCallback();
    this.body.append(h('iframe.d-help-dialog--iframe', { src: this.#contentUrl }));
    this.dialog.classList.add('d-help-dialog');
    this._addDisableAutoPopupCheckBoxIfApplicable();
    this.#initialized = true;
  }

  isAutoPopupLocallyDisabled () {
    return this._isAutoPopupLocallyDisabled(this._readAutoPopupArray());
  }

  _readAutoPopupArray () {
    const storageValue = this.#autoPopupStorage?.getItem(AUTO_POPUP_STORAGE_KEY);
    return storageValue ? JSON.parse(storageValue) : [];
  }

  _isAutoPopupLocallyDisabled (infoAutoPopupArray) {
    return infoAutoPopupArray.some(repositoryItemIdHash => repositoryItemIdHash === this.#repositoryItemIdHashForDisableAutoPopupCheckbox);
  }

  _addDisableAutoPopupCheckBoxIfApplicable () {
    if (!this.#repositoryItemIdHashForDisableAutoPopupCheckbox) {
      return;
    }

    this.footer.append(h('input', {
      type: 'checkbox',
      checked: this.isAutoPopupLocallyDisabled(),
      onchange: event => {
        this._setAutoPopupDisabled(event.target.checked);
      }
    }));
    this.footer.append(h('span', i18n('disableAutoPopup')));
  }

  _setAutoPopupDisabled (setDisabled) {
    const storageValue = this.#autoPopupStorage?.getItem(AUTO_POPUP_STORAGE_KEY);
    const infoAutoPopupArray = storageValue ? JSON.parse(storageValue) : [];
    const currentlyDisabled = this._isAutoPopupLocallyDisabled(infoAutoPopupArray);
    if (!setDisabled && currentlyDisabled) {
      // enable auto-popup by removing repository id hash from the local storage
      const infoAutoPopupArrayWithoutCurrentRepositoryItemIdHash = infoAutoPopupArray
        .filter(repositoryItemIdHash => repositoryItemIdHash !== this.#repositoryItemIdHashForDisableAutoPopupCheckbox);
      this.#autoPopupStorage?.setItem(AUTO_POPUP_STORAGE_KEY, JSON.stringify(infoAutoPopupArrayWithoutCurrentRepositoryItemIdHash));
    } else if (setDisabled && !currentlyDisabled) {
      // disable auto-popup by adding repository id hash to the local storage
      infoAutoPopupArray.push(this.#repositoryItemIdHashForDisableAutoPopupCheckbox);
      this.#autoPopupStorage?.setItem(AUTO_POPUP_STORAGE_KEY, JSON.stringify(infoAutoPopupArray));
    }
  }

}

customElements.define(NAME, HelpDialog);
