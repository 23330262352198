import type { TableData, TableParams } from 'cadenza/api-client/tables-api';
import { tableParams } from 'cadenza/api-client/tables-api';
import { ajax } from 'cadenza/api-client/api';
import type { WorkbookEmbeddingTargetDto } from 'cadenza/workbook/api/workbook-embedding-target-dto';
import type { WorkbookEmbeddingTargetId } from 'cadenza/repository/model/workbook-embedding-target-id';

export const WORKBOOK_EMBEDDING_TARGET_NAME_MAX_LENGTH = 255; // Characters
export const WORKBOOK_EMBEDDING_TARGET_DESCRIPTION_MAX_LENGTH = 4000; // Characters
export const PARAMETER_EMBEDDING_TARGET_NAME = 'embeddingTargetId';

export function getWorkbookEmbeddingTargets (params: TableParams): Promise<TableData<WorkbookEmbeddingTargetDto>> {
  return ajax<TableData<WorkbookEmbeddingTargetDto>>(`/workbook-embedding-targets?${tableParams(params)}`, { dataType: 'json' });
}

export function createEmbeddingTargetUrl (embeddingTargetId: WorkbookEmbeddingTargetId): string {
  // Not using cadenzaUrl(), because we don't want the ;jsessionid to be appended.
  return new URL(`${window.Disy.contextPath}/w/${embeddingTargetId}`, window.origin).toString();
}
