export function settingsJsonParser<T = unknown> (jsonText: string) {
  return JSON.parse(jsonText, (key, value) => {
    if (key === 'settings' || key === 'mapViewSettings') {
      Object.entries(value).forEach(([ settingsKey, settingsValue ]) => {
        try {
          value[settingsKey] = JSON.parse(settingsValue as string);
        } catch (err) {
          // This must be a string, because cannot be JSON parsed.
          value[settingsKey] = settingsValue;
        }
      });
    }
    return value;
  }) as T;
}
