import { MIMETYPE_CSV, MIMETYPE_JSON } from 'cadenza/model/media-type';
import { ajax } from 'cadenza/api-client/api';
import { cadenzaUrl } from 'cadenza/utils/cadenza-url/cadenza-url';
import { download } from 'cadenza/utils/download';
import { logger } from 'cadenza/utils/logging';

/* @see net/disy/cadenza/workbook/performance/rest/WorkbookPerformanceAnalysisEndpointController.java */

export function startOrStopPerformanceAnalysis (recording: boolean) {
  navigator.sendBeacon(cadenzaUrl('/performanceAnalysis/status'), String(recording));
}

export function fetchRecordedEvents () {
  return ajax('/performanceAnalysis/events', { headers: { accept: MIMETYPE_JSON } });
}

export function downloadRecordedEvents () {
  return download(cadenzaUrl('/performanceAnalysis/events'), {
    headers: {
      accept: MIMETYPE_CSV
    }
  });
}

/* @see net/disy/cadenza/web/rest/SessionMetadataEndpointController.java */

//  undefined = net/disy/cadenza/workbook/performance/dto/WorkbookPerformanceEventDto.java */
export function setPerformanceMetadata (metadata: Record<string, string>) {
  return ajax<undefined>('/session/performanceMetadata', {
    type: 'PUT',
    data: JSON.stringify(metadata),
    contentType: MIMETYPE_JSON
  })
    .catch(e => {
      if (e.status === 404) {
        logger.error('Performance Logging Metadata Feature is not enabled.');
      }
    });
}

export function clearPerformanceMetadata () {
  return ajax('/session/performanceMetadata', {
    type: 'DELETE'
  })
    .catch(e => {
      if (e.status === 404) {
        logger.error('Performance Logging Metadata Feature is not enabled.');
      }
    });
}
