import i18n from 'cadenza/hotkeys/keymap.properties';
import { logger } from 'cadenza/utils/logging';
import { Keymap } from 'cadenza/hotkeys/keymap';

/*
This function was extracted from keymap.ts because it is the only reason why the modal module was included in the start page.
By importing modal dynamically, it will only be loaded if the keymap is actually opened.
 */
export function showKeymap () {
  import(/* webpackChunkName: "modal-keymap" */ 'ui/dialog/modal/modal').then(({ Modal }) => {
    new Modal({
      title: i18n('keymapModalTitle'),
      content: () => Promise.resolve(new Keymap())
    }).connectAndShow();
  }).catch(error => logger.error('Error dynamically importing modal', error));
}
