import h from 'hyperscript';

import { getHotkeysForKeyMap, type HotkeyForKeymap } from 'cadenza/hotkeys/hotkeys';
import 'cadenza/hotkeys/hotkey-definitions';

import './keymap.css';
import i18n from './keymap.properties';

const KEYMAP = 'd-keymap';

export class Keymap extends HTMLElement {

  connectedCallback () {
    this.classList.add(KEYMAP);
    const hotkeysDefs = getHotkeysForKeyMap();

    this.append(
      h('d-alert', { type: 'info' }, i18n('info')),
      ...Object.entries(hotkeysDefs)
        .map(([ groupName, hotkeys ]) => createGroupElement(groupName, hotkeys))
    );
  }

}

customElements.define(KEYMAP, Keymap);

function createGroupElement (groupName: string, hotkeys: HotkeyForKeymap[]) {
  return h('.d-hotkey-group.d-panel-section', [
    h('h2', groupName),
    h('table', hotkeys.map(createHotkeyElement))
  ]);
}

function createHotkeyElement (hotkey: HotkeyForKeymap) {
  const formattedKeys = hotkey.hotkey.humanReadableKeyCombo.map(keys => [ ...keys.modifiers, keys.key ]);
  return h(`tr.d-hotkey${hotkey.active ? '.active' : ''}`, [
    h('td',
      formattedKeys.map(
        (keys, comboIndex) => {
          const result = renderCombo(keys);
          if (comboIndex < formattedKeys.length - 1) {
            result.append(', ');
          }
          return result;
        }
      )
    ),
    h('td', hotkey.hotkey.meta?.description)
  ]);
}

function renderCombo (keys: string[]) {
  const renderedKeys = keys.flatMap(
    (key, keyIndex) => renderKey(key, keyIndex === keys.length - 1)
  );
  return h('kbd', renderedKeys);
}

function renderKey (key: string, isLast: boolean): (Element | string | null)[] {
  return [ h('kbd.key', key), isLast ? null : '+' ];
}
